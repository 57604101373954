import React, { Component } from 'react';
import {Form, Breadcrumb, Button} from 'react-bootstrap';

class Pendaftaran extends Component {

  constructor(props){
    super(props);

    this.state={
        isShow: false,
        CB_JALUR : '',
        val_jalur : '',
    }
  }

  componentDidMount() {
    // fetch(global.variable.LINK_API + 'parameter',
    // {
    //   method: 'get',
    //   headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //   },
    //   //body: JSON.stringify(data)
    // })
    // .then(response =>  response.json())
    // .then(resData => {
    //   if(resData.message==="Success"){
    //     localStorage.setItem('DATA_PARAMETER', JSON.stringify(resData.data));

    //     this.setState({CB_JALUR : resData.data.prmRegistrationType, isShow: true});
    //   }
    //   console.log(JSON.parse(localStorage.getItem('DATA_PARAMETER')));
       
    // })

    fetch(global.variable.LINK_API + 'student/flow/12',
    {
      method: 'get',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
      },
      //body: JSON.stringify(data)
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        //localStorage.setItem('DATA_SISWA', JSON.stringify(resData.data));

        this.setState({CB_JALUR : resData.data, isShow:true});

      }
      console.log(resData);
       
    })

    
  }

  /*createSelectItems() {
   

    let items = [];         
     
    items.push(
      <option value="JO">Jalur Olimpiade</option>,
      <option value="JPA">Jalur Prestasi AKademik</option>,
      <option value="JPNA">Jalur Prestasi Non Akademik</option>,
      <option value="JU">Jalur Umum</option>
      );  

    return items;
  }*/

  createSelectItems() {
    let items = [];         
    for (let i = 0; i < this.state.CB_JALUR.length; i++) {             
         items.push(<option key={i} value={JSON.stringify(this.state.CB_JALUR[i])}>{this.state.CB_JALUR[i].id+' - '+this.state.CB_JALUR[i].fullname}</option>);   
    }
    return items;
  }

  onDropdownSelected(e) {
    //console.log("THE VAL", e.target.value);
    console.log(this.state.CB_JALUR, e.target)
    this.setState({val_jalur: e.target.value});
  }

  handleSubmit = event => {
    event.preventDefault();
    console.log(this.state.val_jalur)
    localStorage.setItem('DATA_SISWA', this.state.val_jalur);
    
    if(this.state.val_jalur===""){
      global.swal("Info", "Harap pilih jalur", "info");
    }else{
      localStorage.removeItem('reg1');
      localStorage.removeItem('reg2');
      localStorage.removeItem('reg3');
      localStorage.removeItem('reg4');
      localStorage.removeItem('statereg4');
      localStorage.removeItem('reg5');
      localStorage.removeItem('reg6');
      localStorage.removeItem('reg7');
      window.location.href="/pendaftaran1";
    }
  }

  render() {
    return (
      this.state.isShow?
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">

                      <div className="divContainter">
                        <Breadcrumb>
                          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                          <Breadcrumb.Item active>Pendaftaran</Breadcrumb.Item>
                        </Breadcrumb>

                        <form onSubmit={this.handleSubmit}>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Pilih Siswa</Form.Label>
                            <Form.Control as="select" onChange={this.onDropdownSelected.bind(this)}>
                              <option value="">- Pilih Siswa -</option>
                              {this.createSelectItems()}
                              
                            </Form.Control>
                          </Form.Group>

                          <Button
                            block
                            type="submit">
                            Submit
                          </Button>
                        </form>
                      </div>


                      </div>
                  </div>
              </div>
          </section>
      </div>
      :<div className="content-wrapper"></div>
    );
  }
}

export default Pendaftaran;