import React, { Component } from 'react';
import { Breadcrumb, Table, Button } from 'react-bootstrap';
import ReactExport from "react-export-excel";
import CurrencyFormat from 'react-currency-format';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data_student: '',
      is_show: false,
      data: '',
      totalUpp: 0,
      totalSpp: 0,
      totalUkg: 0,
      rataUpp: 0,
      rataSpp: 0,
      rataUkg: 0
    }
  }

  handleClick = param => e => {
    e.preventDefault();
    localStorage.setItem('student_id', param)
    window.open("/detailstudent", '_blank');
  }

  componentDidMount() {
    let data_report = JSON.parse(localStorage.getItem('data_report'));
    let data = []
    let total = 0;
    let total2 = 0;
    let total3 = 0;
    let rata = 0;
    let rata2 = 0;
    let rata3 = 0;

    data_report.map((item, idx) => {
      let items = Object.create({});
      let spp = item.data_bayar.length > 1 ? parseFloat(item.data_bayar[1].nominal).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0;
      let ukg = item.data_bayar.length > 2 ? parseFloat(item.data_bayar[2].nominal).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0;
      items.fullname = (item.fullname).toUpperCase();
      items.pendaftaran = item.year + '-' + item.registration_type_id + '-' + item.registration_number;
      items.hp = item.hp;
      items.username = item.username.user_id;
      items.status = item.interview_status ?
        item.interview_status.name : '';
      items.pewawancara = item.interview_by_user ?
        item.interview_by_user.user_id : '';
      items.spp = item.interview_by_user && parseInt(item.interview_status_id) !== 3 ?
        'Rp' + spp : '';
      items.ukg = item.interview_by_user && parseInt(item.interview_status_id) !== 3 ?
        'Rp' + ukg : '';
      let nominal2 = 0;
      if (item.interview_by_user && parseInt(item.interview_status_id) !== 3) {
        let potongan2 = item.data_bayar.length > 0 ? parseFloat(item.data_bayar[0].potongan) : 0;
        nominal2 = (item.data_bayar.length > 0 ? parseFloat(item.data_bayar[0].nominal) : 0) - potongan2;
        this.setState({ hasil: nominal2 })
        items.upp = 'Rp' + nominal2.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      } else {
        items.upp = ''
      }

      if (item.interview_by_user && item.interview_status_id !== 3 && item.data_bayar.length > 0) {
        total += nominal2;
      } else {
        items.totalupp = ''
      }

      if (item.interview_by_user && item.interview_status_id !== 3 && item.data_bayar.length > 1) {
        total2 += item.data_bayar[1].nominal !== null ? parseFloat(item.data_bayar[1].nominal) : 0;
      } else {
        items.totalSpp = ''
      }

      if (item.interview_by_user && item.interview_status_id !== 3 && item.data_bayar.length > 2) {
        total3 += item.data_bayar[2].nominal !== null ? parseFloat(item.data_bayar[2].nominal) : 0;
      } else {
        items.totalUkg = ''
      }

      if (item.interview_by_user && item.interview_status_id !== 3 && item.data_bayar.length > 1) {
        rata = total / data_report.length;
      } else {
        items.rataUpp = ''
      }

      if (item.interview_by_user && item.interview_status_id !== 3 && item.data_bayar.length > 1) {
        rata2 = total2 / data_report.length;
      } else {
        items.rataSpp = ''
      }

      if (item.interview_by_user && item.interview_status_id !== 3 && item.data_bayar.length > 2) {
        rata3 = total3 / data_report.length;
      } else {
        items.rataUkg = ''
      }

      items.note = item.note_verification ? item.note_verification : '';
      data.push(items);
    })

    let items = Object.create({});
    items.fullname = 'TOTAL'
    items.upp = 'Rp' + total.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    items.spp = 'Rp' + total2.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    items.ukg = 'Rp' + total3.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    data.push(items);

    let items2 = Object.create({});
    items2.fullname = 'Rata-Rata'
    items2.upp = 'Rp' + rata.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    items2.spp = 'Rp' + rata2.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    items2.ukg = 'Rp' + rata3.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    data.push(items2);

    this.setState({
      report: data, totalUpp: total, totalSpp: total2, rataUpp: rata, rataSpp: rata2, totalUkg: total3, rataUkg: rata3
    }, () => { this.setState({ is_show: true }) })
  }

  render() {
    let data_report = JSON.parse(localStorage.getItem('data_report'));
    let counter = 0;
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Report Wawancara</Breadcrumb.Item>
                  </Breadcrumb>
                  {this.state.is_show ?
                    <ExcelFile element={<Button block style={{ margin: 5, height: 50 }}>Download Data</Button>}>
                      <ExcelSheet data={this.state.report} name="Report Wawancara">
                        <ExcelColumn label="Nama Lengkap" value="fullname" />
                        <ExcelColumn label="Nomor Pendaftaran" value="pendaftaran" />
                        <ExcelColumn label="Username" value="username" />
                        <ExcelColumn label="Status" value="status" />
                        <ExcelColumn label="Pewawancara" value="pewawancara" />
                        <ExcelColumn label="DPP" value="upp" />
                        <ExcelColumn label="Uang Sekolah" value="spp" />
                        <ExcelColumn label="Uang Kegiatan" value="ukg" />
                        <ExcelColumn label="Catatan Tentang Pendaftar" value="note" />
                      </ExcelSheet>
                    </ExcelFile>
                    : ''}

                  <div className="box-body">
                    <div className="row">
                      <Table striped bordered hover size="sm">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Nama Lengkap (Nama dapat diklik)</th>
                            <th>Nomor Pendaftaran</th>
                            <th>Username</th>
                            <th>Status</th>
                            <th>Pewawancara</th>
                            <th>UPP</th>
                            <th>Uang Sekolah</th>
                            <th>Uang Kegiatan</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data_report.map((item, idx) => {
                            let totalupp = 0;
                            let nominal2 = 0;
                            if (item.interview_by_user && item.interview_status_id !== 3) {
                              let potongan2 = item.data_bayar.length > 0 ? parseInt(item.data_bayar[0].potongan) : 0
                              nominal2 = (item.data_bayar.length > 0 ? parseInt(item.data_bayar[0].nominal) : 0) - potongan2
                            } else {
                              nominal2 = 0
                            }

                            if (item.interview_by_user && item.interview_status_id !== 3 && item.data_bayar.length > 0) {
                              totalupp += nominal2;
                            } else {
                              totalupp = ''
                            }
                            counter++;
                            return (
                              <tr>
                                <td>{counter}</td>
                                <td style={{ color: 'blue' }} onClick={this.handleClick(item.id)}>{(item.fullname).toUpperCase()}</td>
                                <td>{item.year + '-' + item.registration_type_id + '-' + item.registration_number}</td>
                                {/* <td>{item.hp}</td> */}
                                <td>{item.username.user_id}</td>

                                <td>{item.interview_status ?
                                  item.interview_status.name : ''}</td>
                                <td>{item.interview_by_user ?
                                  item.interview_by_user.user_id : ''}</td>
                                <td>{item.interview_by_user ?
                                  <CurrencyFormat
                                    input="number"
                                    prefix={'Rp. '}
                                    readOnly
                                    value={nominal2}
                                    maxLength="25" thousandSeparator={true}
                                    style={{ width: '100%' }} displayType={'text'} /> : ''}</td>
                                <td>{item.interview_by_user && item.interview_status_id !== 3 ?
                                  <CurrencyFormat
                                    input="number"
                                    prefix={'Rp. '}
                                    readOnly
                                    value={item.data_bayar.length > 1 ? item.data_bayar[1].nominal : 0}
                                    maxLength="25" thousandSeparator={true}
                                    style={{ width: '100%' }} displayType={'text'} /> : ''}</td>
                                <td>{item.interview_by_user && item.interview_status_id !== 3 ?
                                  <CurrencyFormat
                                    input="number"
                                    prefix={'Rp. '}
                                    readOnly
                                    value={item.data_bayar.length > 2 ? item.data_bayar[2].nominal : 0}
                                    maxLength="25" thousandSeparator={true}
                                    style={{ width: '100%' }} displayType={'text'} /> : ''}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                        <tr>
                          <td style={{ border: 'none' }} colspan="6">TOTAL </td>
                          <td style={{ border: 'none' }}>
                            <CurrencyFormat
                              input="number"
                              prefix={'Rp. '}
                              readOnly
                              value={this.state.totalUpp}
                              maxLength="25" thousandSeparator={true}
                              style={{ width: '100%' }} displayType={'text'} />&nbsp;
                          </td>
                          <td style={{ border: 'none' }}>
                            <CurrencyFormat
                              input="number"
                              prefix={'Rp. '}
                              readOnly
                              value={this.state.totalSpp}
                              maxLength="25" thousandSeparator={true}
                              style={{ width: '100%' }} displayType={'text'} />
                          </td>
                          <td style={{ border: 'none' }}>
                            <CurrencyFormat
                              input="number"
                              prefix={'Rp. '}
                              readOnly
                              value={this.state.totalUkg}
                              maxLength="25" thousandSeparator={true}
                              style={{ width: '100%' }} displayType={'text'} />
                          </td>
                          <td style={{ border: 'none' }}>&nbsp;</td>
                        </tr>
                        <tr>
                          <td style={{ border: 'none' }} colspan="6">RATA-RATA</td>
                          <td style={{ border: 'none' }}>
                            <CurrencyFormat
                              input="number"
                              prefix={'Rp. '}
                              readOnly
                              value={this.state.rataUpp.toFixed(0)}
                              maxLength="25" thousandSeparator={true}
                              style={{ width: '100%' }} displayType={'text'} />
                          </td>
                          <td style={{ border: 'none' }}>
                            <CurrencyFormat
                              input="number"
                              prefix={'Rp. '}
                              readOnly
                              value={this.state.rataSpp.toFixed(0)}
                              maxLength="25" thousandSeparator={true}
                              style={{ width: '100%' }} displayType={'text'} />
                          </td>
                          <td style={{ border: 'none' }}>
                            <CurrencyFormat
                              input="number"
                              prefix={'Rp. '}
                              readOnly
                              value={this.state.rataUkg.toFixed(0)}
                              maxLength="25" thousandSeparator={true}
                              style={{ width: '100%' }} displayType={'text'} />
                          </td>
                          <td style={{ border: 'none' }}>&nbsp;</td>
                        </tr>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Home;