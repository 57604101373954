import React, {Component} from 'react';
import Cookies from 'js-cookie';

export const getAccessToken = () => Cookies.get('access_token')
export const getRefreshToken = () => Cookies.get('refresh_token')
export const isAuthenticated = () => !!getAccessToken()

export const authenticate = async () => {
  if (getRefreshToken()) {
    try {
      //const tokens = await refreshTokens() // call an API, returns tokens

      const tokens = Cookies.get('access_token');

      const expires = (tokens.expires_in || 60 * 60) * 1000
      const inOneHour = new Date(new Date().getTime() + expires)

      // you will have the exact same setters in your Login page/app too
      //Cookies.set('access_token', tokens.access_token, { expires: inOneHour })
      //Cookies.set('refresh_token', tokens.refresh_token)

      Cookies.set('access_token', tokens, { expires: inOneHour })
      Cookies.set('refresh_token', 'refresh_token')

      return true
    } catch (error) {
      redirectToLogin()
      return false
    }
  }

  redirectToLogin()
  return false
}

const redirectToLogin = () => {
  //history.push('/')// if your Login page is inside the same app
  window.location.href="/";
}

/*class AuthenticateBeforeRender extends Component {
  state = {
    isAuthenticated: false,
  }

  componentDidMount() {
    authenticate().then(isAuthenticated => {
      this.setState({ isAuthenticated })
    })
  }

  render() {
    return this.state.isAuthenticated ? this.props.render() : null
  }
}*/


export default class Header extends Component {

    state = {
        isAuthenticated: false,
    }
    
    componentDidMount() {
        authenticate().then(isAuthenticated => {
            this.setState({ isAuthenticated })
        })
    }

    
    logout = () => {
        //unauthenticate();
        Cookies.remove('access_token');
        Cookies.remove('refresh_token');
        localStorage.removeItem('menu');
        window.location.href=localStorage.getItem('redirect');
    }

    changepassword = () => {
      window.location.href="/changepassword";
    }

    
    render(){
        
      return (
          <header className="main-header">
            <span className="logo">
              <span className="logo-mini">RICCI</span>
              <span className="logo-lg">RICCI</span>
            </span>
            <nav className="navbar navbar-static-top">
              <span className="sidebar-toggle" data-toggle="push-menu" role="button">
                  <span className="sr-only">Toggle navigation</span>
              </span>
              <div className="navbar-custom-menu">
                  <ul className="nav navbar-nav">
                      <li className="dropdown messages-menu">
                          <a href="_blank" className="dropdown-toggle" data-toggle="dropdown">
                              <i className="fa fa-cog"></i>
                          </a>
                          <ul className="dropdown-menu">
                              <li>
                                  <ul className="logo-mini">
                                      <li onClick={this.changepassword}>
                                          <div className="logo-mini">
                                            <h4>Change Password</h4>
                                          </div>
                                      </li>
                                      <li onClick={this.logout}>
                                          <div className="logo-mini">
                                            <h4>Logout</h4>
                                          </div>
                                      </li>
                                  </ul>
                              </li>
                          </ul>
                      </li>
                  </ul>
              </div>
            </nav>
          </header>
      )
    }
}