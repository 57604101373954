import React, { Component } from 'react';
import {Breadcrumb, Button, Image} from 'react-bootstrap';
import 'moment/locale/id.js';
import ReactToPrint from "react-to-print";
const moment = require('moment');

class ComponentToPrint extends React.Component {

  constructor(props){
    super(props);

    this.state={
        student_id : '',
        is_show : false,
        image_64 : '',
    }
  }

  componentDidMount() {
    this.setState({student_id: localStorage.getItem('DATA_JALUR').split('-')[0]});
    
    fetch(global.variable.LINK_API + 'student/detail/'+ localStorage.getItem('DATA_JALUR').split('-')[0],
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'),
      },
      //body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        this.setState(resData.data[0]);
        this.setState({is_show : true});
        console.log(this.state);
        
        let id = '';
        this.state.upload_documents[0].document_id===1? id = this.state.upload_documents[0].id : id = '';
        
        if(id!==''){
          fetch(global.variable.LINK_API + 'document/show/'+id,
          {
            method: 'get',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer '+ global.cookies.get('access_token'),
            },
            //body: JSON.stringify(this.state), 
          })
          .then(response =>  response.json())
          .then(resData => {
              console.log(JSON.stringify(resData));
            if(resData.message==="Success"){
              this.setState({image_64 : resData.data[0].link});
            }else{
              let msgerr = "";
              if(resData.errors){
                let count = Object.keys(resData.errors).length;
                for(let i=0; i<count; i++){
                  msgerr = msgerr + resData.errors[Object.keys(resData.errors)[i]] + "\n";
                }
              }
              global.swal(msgerr)
            }
            
          })
        }
      }
    })

    
  }

  render() {
    return (

      this.state.is_show?
        <div id="divPrint" style={{width:810, border: '0px solid black'}}>
            <div style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center' ,textAlign: 'center',}}>
                <img src={require("../assets/images/kop_surat.png")} alt="kop" style={{width:800}}/>
            </div>
            <div style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center', fontSize:20, marginTop:20, textAlign: 'center', }}>
              <label style={{textDecorationLine: 'underline'}}>KARTU TES SELEKSI MASUK JALUR TES (JT)</label>
            </div>

            <div className="rowHorizontal">
              <div style={{width:160, height:160, border: '1px solid black', margin:20, marginLeft:50}}>
                <div style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center', fontSize:15, marginTop:20, textAlign: 'center', textDecorationLine: 'underline'}}>
                  <label>No Pendaftaran</label>
                </div>
                <hr style={{border:'none', height:1, backgroundColor:'black'}}/>
                <div style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center', fontSize:20, marginTop:20, textAlign: 'center', textDecorationLine: 'underline'}}>
                  <label>{this.state.year + '-' + this.state.registration_type_id + '-' + this.state.registration_number}</label>
                </div>
              </div>


              <div style={{width:120, height:160, border: '1px solid black', margin:20, marginLeft: 400}}>
                  {this.state.image_64?
                  <Image alt="Foto" style={{width: '100%', height: '100%'}} src={this.state.image_64} thumbnail />
                  :''}
              </div>
            </div>

            <div className="rowHorizontal">
              <div style={{width:200, margin:5, marginLeft:50}}>
                <label>Nama</label>
              </div>
              <div style={{margin:5}}>
                <label>: {this.state.fullname}</label>
              </div>
            </div>

            <div className="rowHorizontal">
              <div style={{width:200, margin:5, marginLeft:50}}>
                <label>SD Asal</label>
              </div>
              <div style={{margin:5}}>
                <label>: {this.state.school_from_name}</label>
              </div>
            </div>

            <div className="rowHorizontal">
              <div style={{width:200, margin:5, marginLeft:50}}>
                <label>Alamat Rumah</label>
              </div>
              <div style={{margin:5}}>
                <label>: {this.state.address}</label>
              </div>
            </div>

            <div className="rowHorizontal">
              <div style={{width:200, margin:5, marginLeft:50}}>
                <label>Telp.</label>
              </div>
              <div style={{margin:5}}>
                <label>: {this.state.hp}</label>
              </div>
            </div>

            <div className="rowHorizontal">
              <div style={{width:200, margin:5, marginLeft:50}}>
                <label>Ruang Test</label>
              </div>
              <div style={{margin:5}}>
                <label>: {this.state.schedule[0].classroom_id}</label>
              </div>
            </div>

            <div className="rowHorizontal">
              <div style={{width:200, margin:5, marginLeft:50}}>
                <label>Hari, Tanggal</label>
              </div>
              <div style={{margin:5}}>
                <label>: Sabtu, 23 November 2019</label>
              </div>
            </div>

            <div className="rowHorizontal">
              <div style={{width:200, margin:5, marginLeft:50}}>
                <label>Waktu Test</label>
              </div>
              <div style={{margin:5}}>
                <label>: 13.00 – 15.00 WIB</label>
              </div>
            </div>

            <div className="rowHorizontal">
              <div style={{ margin:5, marginLeft:550}}>
                <label>Semarang, {moment(this.state.created_at, 'YYYY-MM-DD').format('DD-MM-YYYY')}</label>
              </div>
            </div>

            <div className="rowHorizontal">
              <div style={{ margin:5, marginLeft:550, marginTop:20}}>
                <label>PANITIA PPDB</label>
              </div>
            </div>

            <div >
              <div style={{ margin:5, marginLeft:50, marginTop:20}}>
                <label style={{fontStyle:'italic'}}>*Peserta tes seleksi hadir paling lambat 15 menit sebelum tes dimulai.</label>
              </div>
              <div style={{ margin:5, marginLeft:50}}>
                <label style={{fontStyle:'italic'}}>*Kartu pendaftaran ini harap dibawa pada waktu pelaksanaan tes seleksi masuk.</label>
              </div>
              <div style={{ margin:5, marginLeft:50}}>
                <label style={{fontStyle:'italic'}}>*Pengumuman pada hari Senin, 25 November 2019 pukul 10.00 WIB melalui menu status pada sistem PPDB online, website dan papan pengumuman SMP PL Domenico Savio.</label>
              </div>
            </div>

        </div>
        : ''
    );
  }
}

class Cetak extends Component {

  constructor(props){
    super(props);

    this.state={
        student_id : '',
    }
  }

  
  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">

                        <div className="divContainter">
                          <Breadcrumb>
                            <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Cetak Test</Breadcrumb.Item>
                          </Breadcrumb>

                          <div>
                            <ReactToPrint
                              trigger={() =>  <Button style={{marginBottom:10}} block>Print</Button>}
                              content={() => this.componentRef}
                            />
                            <ComponentToPrint ref={el => (this.componentRef = el)} />
                          </div>

                          
                        </div>
                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}

export default Cetak;