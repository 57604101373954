import React, { Component } from 'react';
import {Form, Breadcrumb, Button} from 'react-bootstrap';

class Upload_dokumen extends Component {

  constructor(props){
    super(props);

    this.state={
        CB_REGTYPE : '',
        CB_JALUR : '',
        val_jalur : '',
        is_show: false,
    }
  }

  componentDidMount() {
    /*fetch(global.variable.LINK_API + 'student/flow/3',
    {
      method: 'get',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'),
      },
      //body: JSON.stringify(data)
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        //localStorage.setItem('DATA_SISWA', JSON.stringify(resData.data));

        this.setState({CB_JALUR : resData.data});

      }
      console.log(resData);
       
    })*/

    fetch(global.variable.LINK_API + 'parameter',
    {
      method: 'get',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'),
      },
      //body: JSON.stringify(data)
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        this.setState({CB_REGTYPE : resData.data.prmRegistrationType});
        localStorage.setItem('DATA_PARAMETER', JSON.stringify(resData.data));
      }
      console.log(JSON.parse(localStorage.getItem('DATA_PARAMETER')));
       
    })
  }

  createSelectItems() {
    let items = [];         
    for (let i = 0; i < this.state.CB_JALUR.length; i++) {             
      items.push(<option key={i} value={this.state.CB_JALUR[i].id+'-'+this.state.CB_JALUR[i].fullname}>{this.state.CB_JALUR[i].id+' - '+this.state.CB_JALUR[i].fullname}</option>);   
    }
    return items;
  }

  createSelectItemsREG() {
    let items = [];         
    for (let i = 0; i < this.state.CB_REGTYPE.length; i++) {             
      items.push(<option key={i} value={this.state.CB_REGTYPE[i].id}>{this.state.CB_REGTYPE[i].id+' - '+this.state.CB_REGTYPE[i].name}</option>);   
    }
    return items;
  }

  onDropdownSelected(e) {
    console.log([e.target.name] + ' - ' +  e.target.value);
    this.setState({[e.target.name]: e.target.value});
  }

  onDropdownSelectedJalur(e) {
    if(e.target.value===""){
      this.setState({is_show:false});
    }else{

      fetch(global.variable.LINK_API + 'student/flow/3?currentyear=1&registration_type_id='+e.target.value,
      {
        method: 'get',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+ global.cookies.get('access_token'),
        },
        //body: JSON.stringify(data)
      })
      .then(response =>  response.json())
      .then(resData => {
        if(resData.message==="Success"){
          //localStorage.setItem('DATA_SISWA', JSON.stringify(resData.data));

          this.setState({CB_JALUR : resData.data, is_show: true});

        }
        console.log(resData);
        
      })
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    localStorage.setItem('DATA_JALUR', this.state.val_jalur);
    
    if(this.state.val_jalur===""){
      global.swal("Info", "Harap pilih siswa", "info");
    }else{
      const data = { student_id :  this.state.val_jalur.split('-')[0], }

      console.log(data);

      fetch(global.variable.LINK_API + 'student/booking',
      {
        method: 'post',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
        },
        body: JSON.stringify(data), 
      })
      .then(response =>  response.json())
      .then(resData => {
        if(resData.message==="Success"){
            window.location.href="/verifikasidetail";
        }else{
          global.swal("Error", resData.error, "info")
        }
        
      })
      }
  }

  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">

                      <div className="divContainter">
                        <Breadcrumb>
                          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                          <Breadcrumb.Item active>Verifikasi</Breadcrumb.Item>
                        </Breadcrumb>

                        <form onSubmit={this.handleSubmit}>
                          
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Pilih Jalur</Form.Label>
                            <Form.Control as="select" name="val_regtype" onChange={this.onDropdownSelectedJalur.bind(this)}>
                              <option value="">- Pilih Jalur -</option>
                              {this.createSelectItemsREG()}
                              
                            </Form.Control>
                          </Form.Group>
                          
                          {this.state.is_show?
                          <div>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Label>Pilih Siswa</Form.Label>
                              <Form.Control as="select" name="val_jalur" onChange={this.onDropdownSelected.bind(this)}>
                                <option value="">- Pilih Siswa -</option>
                                {this.createSelectItems()}
                                
                              </Form.Control>
                            </Form.Group>
                            <Button
                              block
                              type="submit">
                              Submit
                            </Button>
                          </div>
                          :''}

                          
                        </form>
                      </div>


                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}

export default Upload_dokumen;