import React, { Component } from 'react';
import {Form, Breadcrumb, Button} from 'react-bootstrap';
import 'moment/locale/id.js';
import CurrencyFormat from 'react-currency-format';

class Pendaftaran extends Component {

  constructor(props){
    super(props);

    this.state={
        student_id : '',
        note : '',
        encrypt_64 : '',
        original_name : '',
    }
  }

  getFiles(files){
    if(files[0].type==="image/jpeg" || files[0].type === "image/png"){
      if(files[0].size.split(' ')[0] > 2048){
        global.swal("Error", "Gambar tidak boleh lebih dari 2 MB", "info")
      }
      else{
        this.setState({ encrypt_64: files[0].base64, original_name: files[0].name,  })
      }
    }else{
      global.swal("Error", "Harap pilih gambar berupa (*.jpg atau *.png)", "info")
    }
  }

  onChangeHandler=event=>{
    var file = event.target.files[0];

    if(file.size>2000000){
      global.swal("Error", "File tidak boleh lebih dari 2 MB", "info")
      this.setState({ selectedFile: '', original_name: '',  })
    }else if(file.type==="image/png"||file.type==="image/jpeg"||file.type==="application/pdf"){
      console.log(file);
       this.setState({selectedFile: file, original_name : file.name});
    }else{
      global.swal("Error", "Harap pilih gambar berupa (*.jpg atau *.png) atau pdf", "info")
      this.setState({ selectedFile: '', original_name: '',  })
    }
 
  }

  componentDidMount() {
    this.setState({student_id: localStorage.getItem('DATA_JALUR').split('-')[0]});

    fetch(global.variable.LINK_API + 'student/detail/'+ localStorage.getItem('DATA_JALUR').split('-')[0],
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'),
      },
      //body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        this.setState(resData.data[0]);
        this.setState({is_show2 : true, is_show : true});
        this.setState({student_data : resData.data})
        // console.log(this.state);
        // console.log(resData.data)
      }
    })

    // fetch(global.variable.LINK_API + 'parameter',
    // {
    //   method: 'get',
    //   headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //       'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
    //   },
    //   //body: JSON.stringify(data)
    // })
    // .then(response =>  response.json())
    // .then(resData => {
    //   if(resData.message==="Success"){
    //     localStorage.setItem('DATA_PARAMETER', JSON.stringify(resData.data));
    //   }
    //   console.log(JSON.parse(localStorage.getItem('DATA_PARAMETER')));
       
    // })
    
  }

  fileUploadHandler = event => {

    event.preventDefault();

    if(this.state.original_name ===''){
      global.swal("Error", "Harap pilih file gambar", "info")
    }else{

      global.swal({
        text: 'Apakah anda yakin akan disubmit?',
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: [
          'Cancel',
          {text: 'Submit', 
          closeModal: false,}
        ],
      })
      .then((confirm) => {
        let errorMessage =  { code : 403, message : 'cancel' };
        if (!confirm) throw errorMessage;
        
        const data = new FormData()
        data.append('upload_file', this.state.selectedFile)
        data.append('student_id', localStorage.getItem('DATA_JALUR').split('-')[0])
        data.append('flow_id', '7')
        data.append('original_name', this.state.original_name)
        data.append('note', this.state.note)
        data.append('nama_bank', this.state.nama_bank)
        data.append('nama_rek', this.state.nama_rek)
        data.append('no_rekening', this.state.no_rekening)
        data.append('uang_pendaftaran', this.state.uang_pendaftaran)
        return fetch(global.variable.LINK_API + 'flow/update',
        {
          method: 'post',
          headers: {
              Accept: "application/json",
              'Authorization': 'Bearer '+ global.cookies.get('access_token'),
          },
          body: data, 
        })
      })
      .then(results => {
        return results.json();
      })
      .then(resData => {
        if(resData.message==="Success"){
          console.log(resData);
          global.swal("Success", 'Silahkan menunggu proses verifikasi dan validasi dokumen anda dalam waktu maksimal 24 jam', "success")
          .then((value) => {
            window.location.href="/uploadbukti";
          });
        }
        
      })
      .catch(err => {
        console.log(err)
        if (err) {
          if(err.code!==403){
            global.swal("Error", "Koneksi ke server gagal.", "info")
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });

      
    }
 
  };

  

  onChange = e => this.setState({ [e.target.name]: e.target.value })

  onDropdownSelected(e) {
    this.setState({[e.target.name]: e.target.value});
  }


  handleSubmit = event => {
    event.preventDefault();
    if(this.state.original_name ===''){
      global.swal("Error", "Harap pilih file gambar", "info")
    }else{

      global.swal({
        text: 'Apakah anda yakin akan disubmit?',
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: [
          'Cancel',
          {text: 'Submit', 
          closeModal: false,}
        ],
      })
      .then((confirm) => {
        let errorMessage =  { code : 403, message : 'cancel' };
        if (!confirm) throw errorMessage;
        const data = { student_id :  localStorage.getItem('DATA_JALUR').split('-')[0], flow_id : 7, note: this.state.note, encrypt_64 : this.state.encrypt_64, }
        //console.log(JSON.stringify(data));
        return fetch(global.variable.LINK_API + 'flow/update',
        {
          method: 'post',
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
          },
          body: JSON.stringify(data), 
        })
      })
      .then(results => {
        return results.json();
      })
      .then(resData => {
        if(resData.message==="Success"){
          console.log(resData);
          global.swal("Success", 'Silahkan menunggu proses verifikasi dan validasi dokumen anda dalam waktu maksimal 24 jam', "success")
          .then((value) => {
            window.location.href="/uploadbukti";
          });
        }
        
      })
      .catch(err => {
        console.log(err)
        if (err) {
          if(err.code!==403){
            global.swal("Error", "Koneksi ke server gagal.", "info")
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });

      
    }
  }

  handleBack = () => {
    window.location.href="/uploadbukti";
  }

  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">
                      <div className="divContainter">
                        <Breadcrumb>
                          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                          <Breadcrumb.Item active>Upload Bukti Tansfer</Breadcrumb.Item>
                        </Breadcrumb>
                        
                        <Form onSubmit={this.fileUploadHandler}>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Nama Siswa</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="jalur"
                              value={localStorage.getItem('DATA_JALUR')}
                              disabled />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Nama Bank</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="nama_bank"
                              value={this.state.nama_bank}
                              disabled />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Nama Rekening</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="nama_rek"
                              value={this.state.nama_rek}
                              disabled />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>No. Rekening</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="no_rekening"
                              value={this.state.no_rekening}
                              disabled />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Uang Pendaftaran</Form.Label>
                            <Form>
                              <CurrencyFormat value={this.state.uang_pendaftaran} style={{ width : '100%'}} thousandSeparator={true} prefix={'Rp. '} disabled />
                              </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Catatan</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="note"
                              maxLength="50"
                              value={this.state.note}
                              onChange={this.onChange}  />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Upload file gambar (*jpg dan *.png) atau pdf</Form.Label>
                            <input type="file" name="file" className="form-control" onChange={this.onChangeHandler}/>
                          </Form.Group>
                          <Form.Group>
                          <div className='rowHorizontal'>
                            <Button onClick={this.handleBack} block style={{margin:5, height:50}}>Prev</Button>
                            <Button type="submit" block style={{margin:5, height:50}} >Submit</Button>
                          </div>
                          </Form.Group>
                        </Form>
                    </div>


                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}

export default Pendaftaran;