import React from 'react';
import Cookies from 'js-cookie';
import './App.css';
import './css/all.css';
import Header from './components/Header';
import SideBar from './components/SideBar';
import { BrowserRouter as Router, Switch, Route, Redirect  } from 'react-router-dom';
import Home from './pendaftaran/Home';
import Change_password from './pendaftaran/Change_password';
import Login from './Login';
import Register from './Register';
import LoginAdmin from './LoginAdmin';
import Forgetpassword from './Forgetpassword';
import Confirmation from './Confirmation';
import Image from './Image';

import Angket from './pendaftaran/Angket';
import Angket_detail from './pendaftaran/Angket_detail';
import Pemesanan_seragam from './pendaftaran/Pemesanan_seragam';
import Pemesanan_seragam_detail from './pendaftaran/Pemesanan_seragam_detail';
import Pemesanan_seragam_preview from './pendaftaran/Pemesanan_seragam_preview';

import Pra_pendaftaran from './pendaftaran/Pra_pendaftaran';
import Pendaftaran from './pendaftaran/Pendaftaran';
import Pendaftaran1 from './pendaftaran/Pendaftaran1';
import Pendaftaran2 from './pendaftaran/Pendaftaran2';
import Pendaftaran3 from './pendaftaran/Pendaftaran3';
import Pendaftaran4 from './pendaftaran/Pendaftaran4';
import Pendaftaran5 from './pendaftaran/Pendaftaran5';
import Pendaftaran6 from './pendaftaran/Pendaftaran6';
import Pendaftaran7 from './pendaftaran/Pendaftaran7';
import Cetak from './pendaftaran/Cetak';
import Cetak_test from './pendaftaran/Cetak_test';
import Cetak_wawancara from './pendaftaran/Cetak_wawancara';
import Cetak_detail from './pendaftaran/Cetak_detail';
import Cetak_seragam from './pendaftaran/Cetak_seragam';
import Cetak_seragam_detail from './pendaftaran/Cetak_seragam_detail';

import Detail_student from './pendaftaran/Detail_student';
import Detail_seragam from './pendaftaran/Detail_seragam';
import Detail_angket from './pendaftaran/Detail_angket';

import Upload_dokumen from './pendaftaran/Upload_dokumen';
import Upload_dokumendetail from './pendaftaran/Upload_dokumendetail';
import Upload_bukti from './pendaftaran/Upload_bukti';
import Upload_buktidetail from './pendaftaran/Upload_buktidetail';
import Upload_buktiUpp from './pendaftaran/Upload_buktiUpp';
import Upload_buktiuppdetail from './pendaftaran/Upload_buktiuppdetail';
import Upload_buktiseragam from './pendaftaran/Upload_buktiseragam';
import Upload_buktiseragam_detail from './pendaftaran/Upload_buktiseragam_detail';

import Verifikasi from './pendaftaran/Verifikasi';
import Verifikasi_detail from './pendaftaran/Verifikasi_detail';
import Verifikasi_bukti_bayar from './pendaftaran/Verifikasi_bukti_bayar';
import Verifikasi_bukti_bayar_detail from './pendaftaran/Verifikasi_bukti_bayar_detail';
import Verifikasi_bukti_upp from './pendaftaran/Verifikasi_bukti_upp';
import Verifikasi_bukti_upp_detail from './pendaftaran/Verifikasi_bukti_upp_detail';
import Verifikasi_seragam from './pendaftaran/Verifikasi_seragam';
import Verifikasi_seragam_detail from './pendaftaran/Verifikasi_seragam_detail';

import Wawancara from './pendaftaran/Wawancara';
import Backto_wawancara from './pendaftaran/backto_wawancara';
import Wawancara_detail from './pendaftaran/Wawancara_detail';
import cetak_skfinansial from './pendaftaran/cetak_skfinansial';

import Report_pendaftaran from './pendaftaran/Report_pendaftaran';
import Report_pendaftaran_detail from './pendaftaran/Report_pendaftaran_detail';
import Report_wawancara from './pendaftaran/Report_wawancara';
import Report_wawancara_detail from './pendaftaran/Report_wawancara_detail';
import Report_jadwal_wawancara from './pendaftaran/Report_jadwal_wawancara';
import Report_jadwal_wawancara_detail from './pendaftaran/Report_jadwal_wawancara_detail';
import Report_buktiupp from './pendaftaran/Report_buktiupp';
import Report_buktiupp_detail from './pendaftaran/Report_buktiupp_detail';
import Report_jadwaltest from './pendaftaran/Report_jadwaltest';
import Report_jadwaltest_detail from './pendaftaran/Report_jadwaltest_detail';
import Report_seragam from './pendaftaran/Report_seragam';
import Report_seragam_detail from './pendaftaran/Report_seragam_detail';
import Report_angket from './pendaftaran/Report_angket';
import Report_angket_detail from './pendaftaran/Report_angket_detail';
import Report_nilai from './pendaftaran/Report_nilai';
import Report_nilai_detail from './pendaftaran/Report_nilai_detail';

import Bayar_pendaftaran from './pendaftaran/Bayar_pendaftaran';
import Bayar_pendaftaran_detail from './pendaftaran/Bayar_pendaftaran_detail';


function App() {
  if(Cookies.get('access_token')==undefined&&(window.location.pathname!='/')&&(window.location.pathname!='/admin')){
    window.location.href = localStorage.getItem('redirect')
  }else if(Cookies.get('access_token')){
    if(window.location.pathname=='/'||window.location.pathname=='/admin'){
      console.log(localStorage.getItem('userid'))
      global.swal('Info', 'Anda sedang login sebagai '+ localStorage.getItem('userid'), 'info', {
        buttons: {cancel: "Logout", home:"Kembali ke Home"},
        closeOnClickOutside: false

      }).then((value) => {
          switch (value) {
            case "cancel":
              Cookies.remove('access_token');
              Cookies.remove('refresh_token');
              localStorage.removeItem('menu');
              window.location.reload()
              break;
            case "home":
              window.location.href = '/home'
              break;
          }
      })
    }
  }
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/" component={LoginContainer}/>
          <Route exact path="/admin" component={LoginAdmin}/>
          <Route exact path="/register" component={RegisterContainer}/>
          <Route exact path="/forgetpassword" component={Forgetpassword}/>
          <Route exact path="/confirmation/:id" component={Confirmation}/>
          <Route exact path="/image/:id" component={Image}/>
          <Route component={DefaultContainer}/>

        </Switch>
      </Router>
    </div>
  );
}



const LoginContainer = () => (
  <div>
    <Route exact path="/" render={() => <Redirect to="/" />} />
    <Route path="/" component={Login} />
  </div>
)

const RegisterContainer = () => (
  <div>
    <Route exact path="/" render={() => <Redirect to="/" />} />
    <Route path="/register" component={Register} />
  </div>
)

const DefaultContainer = () => (
  <div>
    <Header/>
    <SideBar/>
    <Route exact path='/home' component={Home} />
    <Route exact path='/changepassword' component={Change_password} />

    <Route path='/pemesananseragam' component={Pemesanan_seragam} />
    <Route path='/pemesananseragamdetail' component={Pemesanan_seragam_detail} />
    <Route path='/pemesananseragampreview' component={Pemesanan_seragam_preview} />

    <Route path='/prapendaftaran' component={Pra_pendaftaran} />
    <Route path='/pendaftaran' component={Pendaftaran} />
    <Route path='/pendaftaran1' component={Pendaftaran1} />
    <Route path='/pendaftaran2' component={Pendaftaran2} />
    <Route path='/pendaftaran3' component={Pendaftaran3} />
    <Route path='/pendaftaran4' component={Pendaftaran4} />
    <Route path='/pendaftaran5' component={Pendaftaran5} />
    <Route path='/pendaftaran6' component={Pendaftaran6} />
    <Route path='/pendaftaran7' component={Pendaftaran7} />
    
    <Route path='/angket' component={Angket} />
    <Route path='/angketdetail' component={Angket_detail}/>
    <Route path='/cetak' component={Cetak} />
    <Route path='/cetakdetail' component={Cetak_detail} />
    <Route path='/cetaktest' component={Cetak_test} />
    <Route path='/cetakwawancara' component={Cetak_wawancara} />
    <Route path='/cetakskfinansial' component={cetak_skfinansial} />
    <Route path='/cetakseragam' component={Cetak_seragam} />
    <Route path='/cetakseragamdetail' component={Cetak_seragam_detail} />


    <Route path='/detailstudent' component={Detail_student} />
    <Route path='/detailseragam' component={Detail_seragam} />
    <Route path='/detailangket' component={Detail_angket} />

    <Route path='/uploaddokumen' component={Upload_dokumen} />
    <Route path='/uploaddokumendetail' component={Upload_dokumendetail} />
    <Route path='/uploadbukti' component={Upload_bukti} />
    <Route path='/uploadbuktidetail' component={Upload_buktidetail} />
    <Route path='/uploadbuktiupp' component={Upload_buktiUpp} />
    <Route path='/uploadbuktiuppdetail' component={Upload_buktiuppdetail} />
    <Route path='/uploadbuktiseragam' component={Upload_buktiseragam} />
    <Route path='/uploadbuktiseragamdetail' component={Upload_buktiseragam_detail} />

    <Route path='/verifikasi' component={Verifikasi} />
    <Route path='/verifikasidetail' component={Verifikasi_detail} />
    <Route path='/verifikasibuktibayar' component={Verifikasi_bukti_bayar} />
    <Route path='/verifikasibuktibayardetail' component={Verifikasi_bukti_bayar_detail} />
    <Route path='/verifikasibuktiupp' component={Verifikasi_bukti_upp} />
    <Route path='/verifikasibuktiuppdetail' component={Verifikasi_bukti_upp_detail} />
    <Route path='/verifikasiseragam' component={Verifikasi_seragam} />
    <Route path='/verifikasiseragamdetail' component={Verifikasi_seragam_detail} />

    <Route path='/wawancara' component={Wawancara} />
    <Route path='/wawancaradetail' component={Wawancara_detail} />
    <Route path='/reportpendaftaran' component={Report_pendaftaran} />
    <Route path='/reportpendaftarandetail' component={Report_pendaftaran_detail} />
    <Route path='/reportwawancara' component={Report_wawancara} />
    <Route path='/reportwawancaradetail' component={Report_wawancara_detail} />
    <Route path='/reportjadwalwawancara' component={Report_jadwal_wawancara} />
    <Route path='/reportjadwalwawancaradetail' component={Report_jadwal_wawancara_detail} />
    <Route path='/reportbuktiupp' component={Report_buktiupp} />
    <Route path='/reportbuktiuppdetail' component={Report_buktiupp_detail} />
    <Route path='/reportjadwaltest' component={Report_jadwaltest}/>
    <Route path='/reportjadwaltestdetail' component={Report_jadwaltest_detail}/>
    <Route path='/reportseragam' component={Report_seragam}/>
    <Route path='/reportseragamdetail' component={Report_seragam_detail}/>
    <Route path='/reportangket' component={Report_angket} />
    <Route path='/reportangketdetail' component={Report_angket_detail} />
    <Route path='/reportnilai' component={Report_nilai} />
    <Route path='/reportnilaidetail' component={Report_nilai_detail} />
    <Route path='/backtowawancara' component={Backto_wawancara} />
	
	<Route path='/bayarpendaftaran' component={Bayar_pendaftaran} />
    <Route path='/bayarpendaftarandetail' component={Bayar_pendaftaran_detail} />
  </div>
)

export default App;
