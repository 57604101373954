import React, { Component } from "react";
import "../css/print.css";
const moment = require("moment");

class ComponentPrint extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      student_id: "",
      student_detail: {},
      fields: {},
      is_show: false,
      newYear: "",
      schoolName: "",
      image_64: "",
    };
  }

  componentDidMount() {
    this.setState({
      student_id: localStorage.getItem("student_id"),
      schoolName: JSON.parse(localStorage.getItem("DATA_PARAMETER"))
        .prmSchoolName.value,
    });

    fetch(
      global.variable.LINK_API +
      "student/detail/" +
      localStorage.getItem("student_id"),
      {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + global.cookies.get("access_token"),
        },
        //body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          this.setState(resData.data[0]);
          if (this.state.note_verification === null) {
            this.setState({ note_verification: "" });
          }
          //this.setState({note_verification : resData.data[0].note_verification});
          let newYear =
            JSON.parse(
              localStorage.getItem("DATA_PARAMETER")
            ).prmStudyYear.filter(
              (el) => el.id === parseInt(this.state.year, 0)
            ) || [];
          newYear = newYear.length > 0 ? newYear[0].name : "";
          this.setState({ is_show: true, newYear });
        }
      });
  }

  form1() {
    return (
      <>
        <tr>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            Jalur
          </td>
        </tr>
        <tr className="bg-grey">
          <td>{this.state.registration_type.name || " - "}</td>
        </tr>
        <tr>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            Nama Lengkap
          </td>
        </tr>
        <tr className="bg-grey">
          <td>{this.state.fullname.toUpperCase() || " - "}</td>
        </tr>
        <tr>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            Nama Panggilan
          </td>
        </tr>
        <tr className="bg-grey">
          <td>
            {this.state.nickname ? this.state.nickname.toUpperCase() : " - "}
          </td>
        </tr>
        <tr>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            Jenis Kelamin
          </td>
        </tr>
        <tr className="bg-grey">
          <td>{this.state.gender ? this.state.gender.name : " - "}</td>
        </tr>
        <tr>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            Golongan Darah
          </td>
        </tr>
        <tr className="bg-grey">
          <td>{this.state.blood_type ? this.state.blood_type.name : " - "}</td>
        </tr>
        <tr>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            NIK
          </td>
        </tr>
        <tr className="bg-grey">
          <td>{this.state.nik || " - "}</td>
        </tr>
        <tr>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            NO KK (Nomor Induk Keluarga)
          </td>
        </tr>
        <tr className="bg-grey">
          <td>{this.state.no_kk || " - "}</td>
        </tr>
        <tr>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            NISN
          </td>
        </tr>
        <tr className="bg-grey">
          <td>{this.state.nisn || " - "}</td>
        </tr>
        <tr>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            Tempat Lahir
          </td>
        </tr>
        <tr className="bg-grey">
          <td>{this.state.pob || " - "}</td>
        </tr>
        <tr>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            Tanggal Lahir
          </td>
        </tr>
        <tr className="bg-grey">
          <td>{moment(this.state.dob, "YYYY-MM-DD").format("DD-MM-YYYY")}</td>
        </tr>
      </>
    );
  }

  form2() {
    return (
      <>
        <tr>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            Agama
          </td>
        </tr>
        <tr className="bg-grey">
          <td>{this.state.religion ? this.state.religion.name : " - "}</td>
        </tr>
        <tr>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            Kewarganegaraan
          </td>
        </tr>
        <tr className="bg-grey">
          <td>{this.state.citizen ? this.state.citizen.name : " - "}</td>
        </tr>
        <tr>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            Alamat Tinggal Di
          </td>
        </tr>
        <tr className="bg-grey">
          <td>{this.state.address || " - "}</td>
        </tr>
        <tr>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            RT
          </td>
        </tr>
        <tr className="bg-grey">
          <td>{this.state.rt || " - "}</td>
        </tr>
        <tr>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            RW
          </td>
        </tr>
        <tr className="bg-grey">
          <td>{this.state.rw || " - "}</td>
        </tr>
        <tr>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            Kelurahan
          </td>
        </tr>
        <tr className="bg-grey">
          <td>{this.state.sub_district || " - "}</td>
        </tr>
        <tr>
          <td>Kecamatan</td>
        </tr>
        <tr className="bg-grey">
          <td>{this.state.district || " - "}</td>
        </tr>
        <tr>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            Kabupaten/Kota
          </td>
        </tr>
        <tr className="bg-grey">
          <td>{this.state.sub_province || " - "}</td>
        </tr>
        <tr>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            Provinsi
          </td>
        </tr>
        <tr className="bg-grey">
          <td>{this.state.province || " - "}</td>
        </tr>

      </>
    );
  }

  form3() {
    const param_unit = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).prmSchoolUnit;
    return (
      <>
        <tr>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            Tinggal Dengan
          </td>
        </tr>
        <tr className="bg-grey">
          <td>{this.state.stay ? this.state.stay.name : " - "}</td>
        </tr>
        <tr>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            Moda Transportasi
          </td>
        </tr>
        <tr className="bg-grey">
          <td>
            {this.state.transportation ? this.state.transportation.name : " - "}
          </td>
        </tr>
        <tr>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            Jarak Tempuh (KM)
          </td>
        </tr>
        <tr className="bg-grey">
          <td>{this.state.school_distance_hour || " - "}</td>
        </tr>
        {param_unit.name == "SPA" || param_unit.name == "SMA" ? (
          <>
            <tr>
              <td
                style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}
              >
                No. Handphone
              </td>
            </tr>
            <tr className="bg-grey">
              <td>{this.state.hp || " - "}</td>
            </tr>
            <tr>
              <td
                style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}
              >
                Email
              </td>
            </tr>
            <tr className="bg-grey">
              <td>{this.state.email || " - "}</td>
            </tr>
          </>
        ) : null}
        <tr>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            Anak ke
          </td>
        </tr>
        <tr className="bg-grey">
          <td>{this.state.child || " - "}</td>
        </tr>
        <tr>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            Dari bersaudara
          </td>
        </tr>
        <tr className="bg-grey">
          <td>{this.state.total_children || " - "}</td>
        </tr>
        <tr>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            Sekolah Asal (Sesuai Identitas Raport)
          </td>
        </tr>
        <tr className="bg-grey">
          <td>
            {this.state.school_from ? this.state.school_from.name : " - "}
          </td>
        </tr>
        <tr>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            Nama Sekolah Asal (Sesuai Identitas Raport)
          </td>
        </tr>
        <tr className="bg-grey">
          <td>{this.state.school_from_name || " - "}</td>
        </tr>
        <tr>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            Alamat Sekolah Asal (Sesuai Identitas Raport)
          </td>
        </tr>
        <tr className="bg-grey">
          <td>{this.state.school_from_address || " - "}</td>
        </tr>
        <tr>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            Keadaan Orang Tua
          </td>
        </tr>
        <tr className="bg-grey">
          <td>
            {this.state.parent_status ? this.state.parent_status.name : " - "}
          </td>
        </tr>
        <tr>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            Keadaan Pekerjaan Orang Tua
          </td>
        </tr>
        <tr className="bg-grey">
          <td>
            {this.state.both_parent_work
              ? this.state.both_parent_work.name
              : ""}
          </td>
        </tr>
      </>
    );
  }

  form4() {
    let guardian = this.state.guardians;
    let showitem = [];

    for (let i = 0; i < guardian.length; i++) {
      showitem.push(
        <React.Fragment key={guardian[i].nik}>
          <tr style={{ paddingTop: 10 }}>
            <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
              Orang Tua
            </td>
          </tr>
          <tr className="bg-grey">
            <td>{guardian[i].guardian_name}</td>
          </tr>
          <tr>
            <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
              Nama Lengkap
            </td>
          </tr>
          <tr className="bg-grey">
            <td>{guardian[i].fullname || " - "}</td>
          </tr>
          <tr>
            <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
              NIK
            </td>
          </tr>
          <tr className="bg-grey">
            <td>{guardian[i].nik || " - "}</td>
          </tr>
          <tr>
            <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
              Tanggal Lahir
            </td>
          </tr>
          <tr className="bg-grey">
            <td>
              {moment(guardian[i].dob, "YYYY-MM-DD").format("DD-MM-YYYY")}
            </td>
          </tr>
          <tr>
            <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
              Agama
            </td>
          </tr>
          <tr className="bg-grey">
            <td>{guardian[i].religion_name || " - "}</td>
          </tr>
          <tr>
            <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
              {guardian[i].guardian_id === 3
                ? "Hubungan Dengan Siswa"
                : "Status"}
            </td>
          </tr>
          <tr className="bg-grey">
            <td>
              {guardian[i].guardian_id === 3
                ? guardian[i].relation
                : guardian[i].status_guardian_name}
            </td>
          </tr>
          <tr>
            <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
              Pendidikan
            </td>
          </tr>
          <tr className="bg-grey">
            <td>{guardian[i].education_name || " - "}</td>
          </tr>
          <tr>
            <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
              Pekerjaan
            </td>
          </tr>
          <tr className="bg-grey">
            <td>{guardian[i].occupation_name || " - "}</td>
          </tr>
          <tr>
            <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
              No HP
            </td>
          </tr>
          <tr className="bg-grey">
            <td>{guardian[i].hp || " - "}</td>
          </tr>
        </React.Fragment>
      );
    }
    return showitem;
  }

  form5() {
    let guardian = this.state.sibling;
    let showitem = [];
    for (let i = 0; i < guardian.length; i++) {
      showitem.push(
        <React.Fragment>
          <tr style={{ paddingTop: 10 }}>
            <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
              Nama
            </td>
          </tr>
          <tr className="bg-grey">
            <td>{guardian[i] ? guardian[i].name.toUpperCase() : " - "}</td>
          </tr>
          <tr>
            <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
              kelas
            </td>
          </tr>
          <tr className="bg-grey">
            <td>{guardian[i].class || " - "}</td>
          </tr>
        </React.Fragment>
      );
    }
    return showitem;
  }

  formdokumen() {
    let guardian = this.state.upload_documents;
    let showitem = [];
    for (let i = 0; i < guardian.length; i++) {
      showitem.push(
        <React.Fragment key={i}>
          <tr>
            <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
              {guardian[i].document_upload_name}
            </td>
          </tr>
          <tr>
            <td>
              {" "}
              {guardian[i].link ? (
                <>
                  {guardian[i].link.split(".").pop() == "pdf" ? (
                    //    <>{guardian[i].link}</>
                    <>Lihat di website</>
                  ) : (
                    // <iframe allowFullScreen frameBorder="0" src={guardian[i].link}
                    // width="800" height="600"></iframe>
                    <img
                      src={guardian[i].link}
                      alt={guardian[i].document_upload_name}
                      style={{ width: "300px" }}
                    />
                  )}
                </>
              ) : (
                "-"
              )}
            </td>
          </tr>
        </React.Fragment>
      );
    }
    return showitem;
  }

  pernyataan() {
    return (
      <React.Fragment>
        <tr style={{ paddingTop: 20 }}>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            &#9745; Data yang dikirim adalah benar
          </td>
        </tr>
        <tr style={{ paddingTop: 20 }}>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            &#9745; Memahami, menyetujui dan mengikuti ketentuan proses PPDB
            seperti yang telah disampaikan
          </td>
        </tr>
        <tr style={{ paddingTop: 20 }}>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            &#9745; Menyetujui bahwa semua pembayaran yang sudah dibayarkan
            bersifat non refundable, non transferable (tidak dapat
            dialihkan/tidak dapat dikembalikan)
          </td>
        </tr>
        <tr style={{ paddingTop: 20 }}>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            &#9745; Apabila calon siswa mengundurkan diri selama proses PPDB
            (sebelum atau sesudah dinyatakan diterima) segala pembayaran
            bersifat non refundable, non transferable dan tidak dapat dialihkan
          </td>
        </tr>
        <tr style={{ paddingTop: 20 }}>
          <td style={{ paddingBottom: 6, paddingTop: 6, fontWeight: "bold" }}>
            Terima kasih bagi Bapak/Ibu yang telah mengisi formulir Registrasi{" "}
            {this.state.schoolName || ""}
          </td>
        </tr>
      </React.Fragment>
    );
  }

  render() {
    return (
      <>
        {this.state.is_show ? (
          <>
            <div
              id="divPrint"
              style={{
                width: "100%",
                // border: "0px solid black",
                marginLeft: 25,
                marginRight: 25,
              }}
            >
              <table className="tablePrint" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                        paddingTop: 25,
                        fontWeight: "bold",
                      }}
                    >
                      <div className="header-space">
                        {`${this.state.schoolName.toUpperCase() || ""
                          } FORM REGISTRASI ${this.state.newYear || ""}`}
                      </div>
                    </td>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th style={{ paddingBottom: 20, width: "100%" }}>
                      Data Siswa
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ paddingBottom: 6, fontWeight: "bold" }}>
                      Nama Siswa
                    </td>
                  </tr>
                  <tr className="bg-grey">
                    <td>
                      {(
                        localStorage.getItem("student_id") +
                        "-" +
                        this.state.fullname
                      ).toUpperCase()}
                    </td>
                  </tr>
                  {this.state.is_show ? this.form1() : null}
                  {this.state.is_show ? this.form2() : null}
                  {this.state.is_show ? this.form3() : null}
                </tbody>
                {this.state.is_show ? (
                  <thead>
                    <tr>
                      <th style={{ width: "35%" }}>Data Orang Tua</th>
                      <th style={{ width: "2%" }}></th>
                      <th></th>
                    </tr>
                  </thead>
                ) : null}
                <tbody>{this.state.is_show ? this.form4() : ""}</tbody>
                {this.state.is_show ? (
                  <thead>
                    <tr>
                      <th style={{ width: "35%", paddingTop: 20 }}>
                        Saudara yang masih bersekolah di Sekolah Ricci
                      </th>
                      <th style={{ width: "2%" }}></th>
                      <th></th>
                    </tr>
                  </thead>
                ) : null}
                <tbody>{this.state.is_show ? this.form5() : ""}</tbody>
                {this.state.is_show ? (
                  <thead>
                    <tr>
                      <th style={{ paddingTop: 20, width: "60%" }}>Dokumen</th>
                    </tr>
                  </thead>
                ) : null}
                <tbody>{this.state.is_show ? this.formdokumen() : null}</tbody>
                {this.state.is_show ? (
                  <thead>
                    <tr>
                      <th style={{ paddingTop: 20, width: "60%" }}>
                        Catatan Dokumen
                      </th>
                    </tr>
                  </thead>
                ) : null}
                <tbody>
                  {this.state.is_show ? (
                    <>
                      <tr className="bg-grey">
                        <td>{this.state.note_document_upload || "-"}</td>
                      </tr>
                    </>
                  ) : null}
                </tbody>
                {this.state.is_show ? (
                  <thead>
                    <tr>
                      <th style={{ paddingTop: 20, width: "60%" }}>
                        Catatan Verifikasi
                      </th>
                    </tr>
                  </thead>
                ) : null}
                <tbody>
                  {this.state.is_show ? (
                    <>
                      <tr className="bg-grey">
                        <td>{this.state.note_verification || "-"}</td>
                      </tr>
                    </>
                  ) : null}
                </tbody>
                {this.state.is_show ? (
                  <thead>
                    <tr>
                      <th style={{ paddingTop: 20 }}>
                        Dengan ini saya orang tua/wali tersebut di atas
                        menyatakan :
                      </th>
                    </tr>
                  </thead>
                ) : null}
                <tbody>{this.state.is_show ? this.pernyataan() : ""}</tbody>
                <tfoot>
                  <tr>
                    <td>
                      <div className="footer-space"></div>
                    </td>
                  </tr>
                </tfoot>
              </table>
              <div className="headerPaper"></div>
              <div className="footerPaper"></div>
            </div>
          </>
        ) : null}
      </>
    );
  }
}

export default ComponentPrint;
