import React, { Component } from 'react';
import {Form, Breadcrumb, Button} from 'react-bootstrap';
import 'moment/locale/id.js';

class Pendaftaran extends Component {

  constructor(props){
    super(props);

    this.state={
        achieve_type_id : '',
        achieve_rank_id : '',
        achieve_type_id_name : '',
        achieve_rank_id_name : '',
        name : '',
        promoter : '',
        rank : '',
    }
  }

  componentDidMount() {
    /*if(localStorage.getItem('reg2')){
      this.setState(JSON.parse(localStorage.getItem('reg2')));
    }*/

    console.log(JSON.parse(localStorage.getItem('DATA_SISWA')).id)
  }

  createSelectAchieveType() {
    let items = [];
    let params = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmAchieveType;
    for (let i = 0; i < params.length; i++) {             
         items.push(<option key={i} value={params[i].id+'_'+params[i].name}>{params[i].name}</option>);   
    }
    return items;
  }

  createSelectAchieveRank() {
    let items = [];
    let params = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmAchieveRank;
    for (let i = 0; i < params.length; i++) {             
      items.push(<option key={i} value={params[i].id+'_'+params[i].name}>{params[i].name}</option>);   
    }
    return items;
  }

  showRemove(){
    if(localStorage.getItem('reg6')){
      return(
        <Button onClick={this.handleClear} block style={{margin:5, height:30, width: 100}}>Clear Data</Button>
      );
    }
  }

  showPrestasi() {
    if(localStorage.getItem('reg6')){
      let guardian = JSON.parse(localStorage.getItem('reg6'));
      console.log(localStorage.getItem('reg6'));
      let showitem = [];

      for (let i = 0; i < guardian.length; i++) { 
        showitem.push(
          <div key={guardian[i].name}>
            <hr/>
            <div className='rowHorizontal'>
              <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Jenis </Form.Label>
              <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].achieve_type_id_name} </Form.Label>
              
            </div>
            <div className='rowHorizontal'>
              <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Nama Prestasi </Form.Label>
              <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].name} </Form.Label>
            </div>
            <div className='rowHorizontal'>
              <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Penyelenggara </Form.Label>
              <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].promoter} </Form.Label>
            </div>
            <div className='rowHorizontal'>
              <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Tingkat </Form.Label>
              <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].achieve_rank_id_name} </Form.Label>
            </div>
            <div className='rowHorizontal'>
              <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Peringkat </Form.Label>
              <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].rank} </Form.Label>
            </div>
          </div>
        );
      }

      return showitem;
    }
  }

  onChange = e => {
    var a = e.target.value;
    a = a.replace("/", "");
    a = a.replace('"', "");
    this.setState({ [e.target.name]: a })
  }

  onDropdownSelected(e) {
    this.setState({[e.target.name]: e.target.value.split('_')[0], [e.target.name + "_name"]: e.target.value.split('_')[1]});
  }

  // handleSubmit = event => {
  //   event.preventDefault();    
    
  //   window.location.href="/pendaftaran7";
    
  // }

  handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();    
    //console.log("state 4 " + JSON.stringify(this.state));
    console.log(this.state)
    
    // let stateData = Object.keys(this.state).map((key) => this.state[key]);
    // let item =[];
    // for (let i = 0; i < stateData.length; i++) { 
    //   let splitData = stateData[i].split('_');
    //   let items =  Object.create({});
    //   items.class_id = splitData[0];
    //   items.subject_id = splitData[1];
    //   items.semester = splitData[2];
    //   items.score = splitData[3];

    //   if(splitData[3]<10){
    //     global.swal("Error", "Nilai tidak boleh kurang dari 10", "info")
    //     return false;
    //   }


    //   item.push(items);
    // }
    
    // console.log("score " + JSON.stringify(item));
    

    // localStorage.setItem('reg7', JSON.stringify(item));

    let reg1 = JSON.parse(localStorage.getItem('reg1'));
    let reg2 = JSON.parse(localStorage.getItem('reg2'));
    let reg3 = JSON.parse(localStorage.getItem('reg3'));
    let reg4 = JSON.parse(localStorage.getItem('reg4'));
    let reg5 = JSON.parse(localStorage.getItem('reg5'));
    let reg6 = JSON.parse(localStorage.getItem('reg6'));
    // let reg7 = JSON.parse(localStorage.getItem('reg7'));

    let statereg4 = JSON.parse(localStorage.getItem('statereg4'));

    const result = {};
    Object.keys(reg1).forEach(key => result[key] = reg1[key]);
    Object.keys(reg2).forEach(key => result[key] = reg2[key]);
    Object.keys(reg3).forEach(key => result[key] = reg3[key]);
    result['guardian'] = reg4;
    result['sibling'] = reg5;
    result['achieve'] = reg6;
    result['id'] = JSON.parse(localStorage.getItem('DATA_SISWA')).id;
    // result['score'] = reg7;
    result['parent_status_id'] = statereg4.parent_status_id;
    result['both_parent_work'] = statereg4.both_parent_work;

    console.log(JSON.stringify(result));
    
    const data = { data:JSON.stringify(result) }

    global.swal({
      text: 'Apakah anda yakin akan disubmit?',
      closeOnEsc: false,
      closeOnClickOutside: false,
      buttons: [
        'Cancel',
        {text: 'Submit', 
        closeModal: false,}
      ],
    })
    .then((confirm) => {
      let errorMessage =  { code : 403, message : 'cancel' };
      if (!confirm) throw errorMessage;
      return fetch(global.variable.LINK_API + 'student/store',
      {
        method: 'post',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
        },
        body: JSON.stringify(data), 
      });
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        console.log(resData);
        localStorage.removeItem('reg1');
        localStorage.removeItem('reg2');
        localStorage.removeItem('reg3');
        localStorage.removeItem('reg4');
        localStorage.removeItem('statereg4');
        localStorage.removeItem('reg5');
        localStorage.removeItem('reg6');
        // localStorage.removeItem('reg7');
        global.swal("Success", resData.message, "success", )
        .then((value) => {
          window.location.href="/uploaddokumen";
        });
      }else{
        global.swal("Error", resData.error, "info")
      }
    })
    .catch(err => {
      if (err) {
        if(err.code!==403){
          global.swal("Error", "Koneksi ke server gagal.", "info")
        }
      } else {
        global.swal.stopLoading();
        global.swal.close();
      }
    });


    /*const data = { data:JSON.stringify(result) }

    fetch(global.variable.LINK_API + 'student/store',
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
      },
      body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        console.log(resData);
        localStorage.removeItem('reg1');
        localStorage.removeItem('reg2');
        localStorage.removeItem('reg3');
        localStorage.removeItem('reg4');
        localStorage.removeItem('statereg4');
        localStorage.removeItem('reg5');
        localStorage.removeItem('reg6');
        localStorage.removeItem('reg7');
        global.swal("Success", resData.message, "success")
        .then((value) => {
          window.location.href="/pendaftaran";
        });
        //window.location.href="/pendaftaran";
      }
       
    })*/

    //let regx = Object.assign(reg1, reg2)

    //console.log(JSON.stringify(regx));

    //window.location.href="/pendaftaran5";
    
  }

  handleAdd = () => {
    console.log("state 4 " + JSON.stringify(this.state));
    let item =[];
    if(localStorage.getItem('reg6')){
      item = JSON.parse(localStorage.getItem('reg6'));
    }
    item.push(this.state);

    
    console.log("score " + JSON.stringify(item));

    localStorage.setItem('reg6', JSON.stringify(item));
    
    window.location.href="/pendaftaran6";
  }

  handleClear = () =>{
    localStorage.removeItem('reg6');
    window.location.href="/pendaftaran6";
  }

  handleBack = () => {
    window.location.href="/pendaftaran5";
  }

  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">
                      <div className="divContainter">
                        <Breadcrumb>
                          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                          <Breadcrumb.Item active>Pendaftaran Step 6</Breadcrumb.Item>
                        </Breadcrumb>
                        
                        <Form onSubmit={this.handleAdd}>
                          <Form.Label style={{ fontSize: 20, textDecorationLine: 'underline'}} >Prestasi</Form.Label>

                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Jenis</Form.Label>
                            <Form.Control as="select" name="achieve_type_id" onChange={this.onDropdownSelected.bind(this)} value={this.state.achieve_type_id + '_' + this.state.achieve_type_id_name} required>
                              <option value="">-Pilih Jenis-</option>
                              {this.createSelectAchieveType()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Nama Prestasi</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="name"
                              maxLength="100"
                              value={this.state.name}
                              onChange={this.onChange} />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Penyelenggara</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="promoter"
                              maxLength="100"
                              value={this.state.promoter}
                              onChange={this.onChange}  />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Tingkat</Form.Label>
                            <Form.Control as="select" name="achieve_rank_id" onChange={this.onDropdownSelected.bind(this)} value={this.state.achieve_rank_id + '_' + this.state.achieve_rank_id_name} required>
                              <option value="">-Pilih Tingkat-</option>
                              {this.createSelectAchieveRank()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Peringkat</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="rank"
                              maxLength="5"
                              value={this.state.rank}
                              onChange={this.onChange}  />
                          </Form.Group>

                          <div className='rowHorizontal'>
                            <Button  type="submit" block style={{margin:5, height:50}}>Tambah</Button>
                          </div>
                          

                          {this.showRemove()}
                          {this.showPrestasi()}
                          <div className='rowHorizontal'>
                            <Button onClick={this.handleBack} block style={{margin:5, height:50}}>Prev</Button>
                            <Button onClick={this.handleSubmit} block style={{margin:5, height:50}} >Submit</Button>
                          </div>
                        </Form>
                    </div>


                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}

export default Pendaftaran;